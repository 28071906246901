import React from 'react'
import LayoutConstant from '@lib/constants/layout.constant'
import PagePathConstant from '@lib/constants/page-path.constant'
import SystemHelper from '@lib/helpers/system.helper'

function NotFoundPage() {
  return <></>
}

NotFoundPage.pageMeta = {
  metaTitle: SystemHelper.getCurrentPageName('Not found'),
  metaDescription: '',
  metaKeywords: '',
  path: PagePathConstant.NOT_FOUND,
  layout: LayoutConstant.Error,
}

export default NotFoundPage
